<template>
  <div>
    <el-row>
      <el-col :span="24" class="infoTit">
        <span v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==0" class="tag bgRed titColor radiusBox">OTC</span>
        <span v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==1"
          class="tag bgGreen titColor radiusBox">OTC</span>
        <span v-if="goodsinfo.otcType===0" class="tag bgRed titColor radiusBox">Rx</span>
        <span v-if="goodsinfo.newStatus==true||goodsinfo.newStatus=='true'" class="tag bgGreen titColor">新品</span>
        <span v-if="goodsinfo.medicalInsuranceType>0" class="tag bgBlue titColor">医保</span>
        <span v-if="goodsinfo.proprietary==1" class="tag bgOrange titColor">自营</span>
        <span class="brandName">{{goodsinfo.brandName}} </span>
        <!-- {{ goodsinfo.name }} -->
         <span v-if="goodsinfo.alias==''||goodsinfo.alias==null">{{ goodsinfo.name }}  </span>
         <span v-else>{{ goodsinfo.alias }}  </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg">
        <span>{{ goodsinfo.subTitle }}</span>
      </el-col>
    </el-row>
    <el-row class="infoBox">
      <el-col :span="6">
        <span class="infoKey">会员价：</span><span
          class="price"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span></el-col>
      <el-col :span="6">
        <span class="infoKey">推荐零售价：</span>￥{{
          goodsinfo.price==="***"?"会员可见":goodsinfo.originalPrice
        }}</el-col>
      <el-col :span="6"><span class="infoKey">毛利率：</span>{{ goodsinfo.grossProfitMargin }}%</el-col>
      <el-col :span="6"><span class="infoKey">净赚：</span>￥{{ goodsinfo.price==="***"?"会员可见":goodsinfo.earnings }}
      </el-col>
      <el-col :span="24" class="infoActivity" >
        <span class="infoKey">促销活动:</span>
        <span class="infoTag">小额包邮</span>
      </el-col>
      <!-- <el-col :span="24" class="infoActivity">
        <span class="infoKey"></span>
        <span class="infoTag">免运费</span>
        <span class="infoDes">该商品免运费</span>
      </el-col> -->
    </el-row>
    <el-row :span="24" class="infoParameter">
      <div><span class="infoKey">商品编号:</span><span>{{goodsinfo.productSn }}</span></div>
      <div><span class="infoKey">国药准字:</span><span>{{goodsinfo.approvalNo}}</span></div>
      <div><span class="infoKey">药品规格:</span><span>{{goodsinfo.specification}}</span></div>
      <div><span class="infoKey">件装规格:</span><span>{{goodsinfo.partSpecification}}</span></div>
      <div><span class="infoKey">生产日期:</span><span>{{goodsinfo.producedDateFromat}}</span></div>
      <div><span class="infoKey">效期优于:</span><span>{{goodsinfo.validDateFromat}}</span></div>
      <div><span
          class="infoKey">医保类型:</span><span>{{goodsinfo.medicalInsuranceType===0?'非医保':goodsinfo.medicalInsuranceType===1?'甲类医保':'乙类医保'}}</span>
      </div>
      <div>
        <span class="infoKey" style="text-indent: 30px;">库存:</span>
        <!-- <span>{{goodsinfo.fpQuantity==-1 ||goodsinfo.fpQuantity==0 ?'备货中':goodsinfo.fpQuantity>1000?"充足":goodsinfo.fpQuantity+goodsinfo.unit}}</span> -->
        <span v-if="goodsinfo.fpQuantity==-1 || goodsinfo.fpQuantity< Number(goodsinfo.minOrderNum)">备货中</span>
        <span v-if="goodsinfo.fpQuantity>1000">充足</span>
        <span
          v-if="goodsinfo.fpQuantity<=1000 && goodsinfo.fpQuantity>=Number(goodsinfo.minOrderNum)">{{goodsinfo.fpQuantity}}{{goodsinfo.unit}}</span>
      </div>
      <div v-if="goodsinfo.fpQuantity<1 && goodsinfo.deliveryTimeFormat"><span
          class="infoKey">到货日期:</span><span>{{goodsinfo.deliveryTimeFormat}}</span></div>
      <div @click="Download(goodsinfo.id)"> <span class="infoKey">首营资料：</span> <strong class="downBtn">查看/下载</strong>
      </div>
    </el-row>

    <el-row class="infoNumBox">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <el-input-number v-model="num" @change="handleChange" :min="goodsinfo.minOrderNum"
          :max="Number(goodsinfo.fpQuantity)" :step="goodsinfo.minOrderNum" step-strictly></el-input-number>
      </el-col>
    </el-row>
    <div class="infoBtns">
      <div :span="24">
        <span class="infoKey"></span>
        <button :disabled="goodsinfo.fpQuantity< Number(goodsinfo.minOrderNum)"
          :class="goodsinfo.fpQuantity< Number(goodsinfo.minOrderNum)?'grey shopBtn':'shopBtn'"
          @click="immediateBuy(goodsinfo)">
          立即购买
        </button>
        <button :disabled="goodsinfo.fpQuantity< Number(goodsinfo.minOrderNum)"
          :class="goodsinfo.fpQuantity< Number(goodsinfo.minOrderNum)?'grey addBtn':'addBtn'"
          @click="addCartFun(goodsinfo)">加入购物车</button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
  @import "~style/index.less";

  .info {
    overflow: hidden;

    .infoTit {
      font-size: 20px;
      line-height: 30px;
      color: #333;
      font-weight: bold;

      .brandName {
        font-weight: bolder;
        color: #333;
      }

      .tag {
        width: 42px;
        text-align: center;
        height: 20px;
        // padding: 0 5px;
        line-height: 19px;
        margin-top: 2px;
        border-width: 1px;
        font-size: 14px;
        border-style: solid;
        box-sizing: border-box;
        white-space: nowrap;
        display: inline-block;
        margin-right: 3px;

        &.titColor {
          color: #fff
        }

        &.radiusBox {
          border-radius: 50%;
        }

        &.bgRed {
          background-color: #DD1F1F;
          border-color: #DD1F1F;
        }

        &.bgBlue {
          background-color: #409eff;
          border-color: #409eff;
        }

        &.bgGreen {
          background-color: #52C41A;
          border-color: #52C41A;
        }

        &.bgOrange {
          background-color: #FF7A45;
          border-color: #FF7A45;
        }
      }
    }

    .infoMsg {
      color: #FFD303;
      font-size: 14px;
      line-height: 30px;
      font-weight: bold;
      margin-top: 5px;
    }

    span.infoKey {
      width: 86px;
      text-align: left;
      display: inline-block;
      color: #666;
      font-size: 14px;
    }

    .downBtn {
      cursor: pointer;

      &:hover {
        color: #FFD303;
      }
    }

    .infoNumBox {
      padding: 0px 10px;
      margin-bottom: 25px;
    }

    .infoBtns {
      padding: 0px 10px;
      z-index: 9999;
      position: relative;
      margin-bottom: 20px;

      button {
        width: 150px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        font-size: 15px;
        font-weight: bold;
        margin-top: 8px;
        border-radius: 0;
        padding: 0;

        &.addBtn {
          background: #FFD303;
          color: #000;
          margin-left: 20px;
          border: 1px solid #FFD303;
          outline: none;
        }

        &.shopBtn {
          background: #ff3333;
          color: #fff;
          border: 1px solid #ff3333;
          outline: none;
        }

        &.grey {
          background: #d4d2cf;
          color: #333;
          border: 1px solid #d4d2cf;
        }
      }
    }

    .infoParameter {
      padding: 0px 10px 25px;
      border-bottom: 1px solid #f6f6f6;
      margin-bottom: 20px;
      color: #333;

      div {
        float: left;
        width: 50%;
        margin-bottom: 15px;

        &.infoActivity {
          margin-bottom: 12px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      // .el-col {
      //   margin-bottom: 15px;

      // }
    }

    .infoBox {
      background: #f6f6f6;
      padding: 20px 10px 0 10px;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      margin-bottom: 25px;
      margin-top: 10px;

      .el-col {
        margin-bottom: 25px;

        &.infoActivity {
          margin-bottom: 12px;
        }

        &:last-child {
          margin-bottom: 25px;
        }
      }

      span.price {
        color: #FFD303;
        font-size: 16px;
        font-weight: bold;

        b {
          font-size: 12px;
        }
      }

      span.infoTag {
        border: 1px solid #FFD303;
        color: #FFD303;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        padding: 0 10px;
        margin: 0 10px;
      }

      span.infoDes {
        color: #666;
      }
    }
  }
</style>
<script>
  import {
    AddCart,
    DownloadFirstTest
  } from "api/product.js"
  import {
    mapState,
    mapMutations,
    mapGetters
  } from "vuex";
  export default {
    name: "GoodsInfo",
    data() {
      return {
        num: 0,
        promotionMap: []
      };
    },
    props: {
      goodsinfo: {
        type: Object,
      },
    },
    components: {},
    computed: {
      //计算属性可实时更新购物车数量
      CarGoodsNum() {
        return this.$store.getters.CarGoodsNum
      },
      ...mapState({
        goodsnum: state => state.carGoodsNum,
      })
    },
    methods: {
      Download(goodsId) {
        // 显示loading
        this.$loading({
          fullscreen: true,
          background: '#ffffff45'
        })
        //  alert(goodsId)
        DownloadFirstTest(goodsId).then(res => {
          if (res.data.code == 400) {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            });
            // 关闭loading
            this.$loading().close();
          } else {
            let href = res.data.data;
            // 关闭loading
            this.$loading().close();
            window.open(href, '_blank', 'toolbar=yes, width=900, height=700')
          }


        })
      },
      ...mapMutations({
        setCarGoodsNum: "setCarGoodsNum",
      }),
      ...mapGetters({
        typeQuantity: "typeQuantity"
      }),
      handleChange() {
        // value
      },
      // 加入购物车
      addCartFun(data) {
        if (this.goodsinfo.price === "***") {
          this.$message({
            message: '请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
            type: "warning"
          })
          return false
        }
        if (this.goodsinfo.cumsum >= this.goodsinfo.times) {
          this.$message({
            message: '您的小额包邮使用次数已经用完，请正常下单',
            type: "warning"
          })
          return
        }
        // {"isRecentExpiration":0,"quantity":10,"productId":1}
        const addcartDate = [];
        addcartDate.push({
          isRecentExpiration: data.isRecentExpiration,
          quantity: this.num,
          productId: data.id,
          goodNum: data.goodNum,
          cartType: data.cartType,
          promotionId: data.promotionId
        })
        // 加入购物车接口
        AddCart(addcartDate).then((data) => {
          if (data.data.code == 200) {
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });

            let count = this.num
            this.setCarGoodsNum(count);
          } else {
            this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
          }
        })
      },
      // 立即购买
      immediateBuy(data) {

        if (this.goodsinfo.price === "***") {
          this.$message({
            message: '未认证用户不能购买',
            type: "warning"
          })
          return false
        }
        if (this.goodsinfo.cumsum >= this.goodsinfo.times) {
          this.$message({
            message: '您的小额包邮使用次数已经用完，请正常下单',
            type: "warning"
          })
          return
        }
        const addcartDate = [];
        addcartDate.push({
          isRecentExpiration: data.isRecentExpiration,
          quantity: this.num,
          productId: data.id,
          goodNum: data.goodNum,
          cartType: data.cartType,
          promotionId: data.promotionId
        })
        // 加入购物车接口
        AddCart(addcartDate).then((data) => {
          if (data.data.code == 200) {
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });

            let count = this.num
            this.setCarGoodsNum(count);
            this.$router.push({
              name: "Cart"
            });

          } else {
            this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
          }
        })
      }
    },
    mounted() {},
    watch: {
      goodsinfo: function () {},
    },
    created() {
      this.num = this.goodsinfo.minOrderNum
      this.promotionMap = this.goodsinfo.promotionMap
    },
  };
</script>